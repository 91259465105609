<template>
  <div>
    <Header
      class="flex"
      :back="true"
      route="/controller"
      :text="$t('Components.Generic.back')"
    >
      <h5 class="center-text header-title">
        {{ $t('Components.Controller.desk_settings') }}
      </h5>
    </Header>
    <!-- TODO: height into measurement (i.e. cm or in) -->
    <Card position="bottom">
      <!-- Units  -->
      <SettingsListItem
        class="list-item margin margin-medium-large margin-bottom"
        :title="$t('Components.Controller.unit')"
        @click="$router.push('/controller/settings/units')"
      >
        <LinkSlotItem
          :text="unitText"
        />
      </SettingsListItem>
      <!-- Offset -->
      <SettingsListItem
        class="list-item margin margin-medium-large margin-bottom"
        :title="$t('Components.Controller.calibrate')"
        @click="$router.push(`/controller/settings/offset`)"
      >
        <LinkSlotItem
          :text="position"
        />
      </SettingsListItem>
      <SettingsListItem
        class="list-item margin margin-medium-large margin-bottom"
        :title="$t('Components.Controller.desk_limits')"
        @click="$router.push(`/controller/settings/desklimits`)"
      >
        <LinkSlotItem
          :text="hasActiveLimits"
        />
      </SettingsListItem>
      <!-- Shortcuts
      <SettingsListItem
        class="list-item margin margin-medium-large margin-bottom"
        :title="$t('Components.Controller.shortcuts')"
        @click="$router.push(`/controller/settings/shortcuts`)"
      >
        <LinkSlotItem
          :text="shortcutsValue ? $t('Components.Controller.on') : $t('Components.Controller.off')"
        />
      </SettingsListItem>
      -->
      <!-- Safe Control -->
      <SettingsListItem
        :info-icon="true"
        class="list-item margin margin-medium-large margin-bottom"
        :title="$t('Components.Controller.safe_control')"
        :info-body="$t('Components.Controller.safe_control_tooltip')"
      >
        <BFormCheckbox
          v-model="safeControlValue"
          switch
          @change="updateSafeControl()"
        />
      </SettingsListItem>
    </Card>
  </div>
</template>

<script>
import SettingsListItem from '@/components/listitem/settings.list.item.vue';
import Header from '@/components/headers/header.vue';
import Card from '@/components/card/card.vue';
import { BFormCheckbox } from 'bootstrap-vue';
import ConnectState from '@/singletons/connect.state.singleton';
import UserState from '@/singletons/user.state.singleton';
import UserService from '@/Services/User/user.service';
import LinkSlotItem from '@/components/linkslotitem/link.slot.item.vue';

export default {
  components: {
    SettingsListItem,
    Header,
    LinkSlotItem,
    Card,
    BFormCheckbox,
  },
  data() {
    return {
      shortcutsValue: false,
      safeControlValue: false,
    };
  },
  computed: {
    device() { return ConnectState.selectedDevice; },
    measurementSystem() {
      const { MeasurementSystem } = UserState.profile;
      return MeasurementSystem;
    },
    measurementUnit() {
      if (this.measurementSystem === 'METRIC') return 'cm';
      return 'in';
    },
    position() {
      return `${this.device.getPosition(this.measurementUnit)} ${this.measurementUnit}`;
    },
    highLimitSameAsMaxHeight() { return Math.abs(this.device.highLimit - this.device.maxHeight) < 5; },
    lowLimitSameAsMaxHeight() { return Math.abs(this.device.lowLimit - this.device.minHeight) < 5; },
    hasActiveLimits() {
      if ((this.highLimitSameAsMaxHeight) && (this.lowLimitSameAsMaxHeight)) {
        return this.$t('Components.Controller.inactive_limits');
      }
      return this.$t('Components.Controller.active_limits');
    },
    unitText() {
      return this.measurementSystem === 'METRIC' ? this.$t('Components.Controller.metric') : this.$t('Components.Controller.imperial');
    },
  },
  created() {
    this.shortcutsValue = !!UserState.profile.shortcut;
    this.safeControlValue = !!UserState.profile.safecontrol;
  },
  methods: {
    async updateSafeControl() {
      if (this.safeControlValue) {
        this.shortcutsValue = false;
        await UserService.saveProfile('shortcut', this.shortcutsValue);
        await UserService.saveProfile('safecontrol', this.safeControlValue);
      } else {
        await UserService.saveProfile('safecontrol', this.safeControlValue);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.list-item{
  cursor: pointer;
}
</style>
