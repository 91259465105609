<template>
  <div class="link-slot-wrapper">
    <router-link
      v-if="route"
      class="link"
      :to="route"
    >
      <p class="normal-regular">
        {{ text }}
      </p>
      <svg-icon src="../icons/arrow_right.svg" />
    </router-link>
    <div
      v-else
      class="link"
    >
      <p class="normal-regular">
        {{ text }}
      </p>
      <svg-icon src="../icons/arrow_right.svg" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    route: {
      required: false,
      default: null,
      type: String,
    },
    text: {
      required: false,
      default: '',
      type: String,
    },
  },

};
</script>

<style lang="scss">
  .link-slot-wrapper {
      .link{
        display: flex;
        flex-direction: row;
        margin: 0;
        gap: 1.125rem;
        align-items: baseline;

        h6 {
          margin: 0;
        }

        p {
          margin: 0;
        }
        span.icon {
          svg {
            width: 0.85rem;
            height: 0.85rem;
          }
        }
      }
    }
</style>
